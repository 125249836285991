.outerFooterMain {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(51, 50, 53);
  position: fixed;
  bottom: 0;
  height: 80px;
  width: 100%;
}

.gitHub > img {
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  position: relative;
  top: 10%;
}
.linkedIn > img {
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  position: relative;
  top: 10%;
}
.resume_PDF > img {
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  position: relative;
  top: 10%;
}
.gitHub {
  margin: 0 15px;
  width: 60px;
  height: 60px;
}
.linkedIn {
  margin: 0 15px;
  width: 60px;
  height: 60px;
}
.resume_PDF {
  margin: 0 15px;
  width: 60px;
  height: 60px;
}
.gitHub:hover {
  border-radius: 5px;
  /* box-shadow: 0 0 10px aquamarine; */
  box-shadow: 0 0 10px rgb(231, 96, 82);
}

.linkedIn:hover {
  border-radius: 5px;
  /* box-shadow: 0 0 10px aquamarine; */
  box-shadow: 0 0 10px rgb(231, 96, 82);
}

.resume_PDF:hover {
  border-radius: 5px;
  /* box-shadow: 0 0 10px aquamarine; */
  box-shadow: 0 0 10px rgb(231, 96, 82);
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .outerFooterMain {
    position: static;
  }
}
