.aboutMeContainer {
  background-color: rgb(231, 96, 82);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
}

.outerAboutMe {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 700px;
  min-height: 100vh;
}
.outInfo-section {
  display: flex;
  flex-direction: column;
}
.circularPortrait img {
  width: 22em;
  height: 22em;
  border-radius: 50%;
  margin: 15px;
  object-fit: cover;
  object-position: 50% 70%;
}
.titleAboutMe {
  font-size: 40px;
  letter-spacing: -3px;
  font-weight: bold;
}
.informationAboutMe {
  font-size: 20px;
  margin: 10px;
  text-align: left;
  padding: 0 15px 20px 15px;
}
@media screen and (width: 1024px) {
  .circularPortrait img {
    width: 25em;
    height: 25em;
    border-radius: 50%;
    margin: 15px;
  }
  .informationAboutMe {
    font-size: 25px;
    margin: 10px;
    text-align: left;
    padding: 0 15px 0 15px;
    width: 350px;
  }
}
@media screen and (max-width: 700px) {
  .circularPortrait img {
    width: 18em;
  }
}
@media screen and (max-width: 615px) {
  .outerAboutMe {
    width: 100%;
    justify-content: center;
  }
  .circularPortrait img {
    width: 15em;
    height: 18em;
  }
}
@media screen and (max-width: 570px) {
  .aboutMeContainer {
    padding-top: 0;
  }
  .outerAboutMe {
    flex-direction: column;
    justify-content: start;
  }
  .circularPortrait img {
    border-radius: 50%;
    margin: 120px 10px 10px 10px;
  }
}
@media screen and (max-width: 520px) {
  .informationAboutMe {
    height: 100%;
    width: 300px;
  }
}

@media screen and (max-width: 375px) {
  .informationAboutMe {
    font-size: 22px;
  }
}

@media screen and (max-width: 355px) {
  .informationAboutMe {
    font-size: 20px;
    width: 250px;
  }
}
